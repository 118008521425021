export const getMixPanelIds = (
  internalMixPanelIds: string[],
  clientMixPanelIds: string[]
) => {
  const samplingRate = 0.05; // track only 5% of users;

  // Check if mixpanelRandomValue is already present in localStorage
  const storedRandomValue = localStorage.getItem("mixpanelRandomValue");

  let randomValue: number;

  if (storedRandomValue) {
    randomValue = parseFloat(storedRandomValue);
  } else {
    randomValue = Math.random();
    localStorage.setItem("mixpanelRandomValue", randomValue.toString());
  }

  let trackIds = [];

  if (randomValue < samplingRate && internalMixPanelIds?.length > 0) {
    trackIds = [...internalMixPanelIds, ...clientMixPanelIds];
  } else {
    trackIds = [...clientMixPanelIds];
  }
  return trackIds;
};
