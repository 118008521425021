import { BaseSelector } from "./baseSelector";

class PageSelector extends BaseSelector {

  getMenuPage() {
    const menuPage = this.config.theme.pages?.find(
      (page: any) => page?._id === "menu"
    );
    return menuPage;
  }

  getOrderHistoryPage() {
    const orderHistoryPage = this.config.theme.pages?.find(
      (page: any) => page?._id === "order_history"
    );
    return orderHistoryPage;
  }

  getItemDetailPage() {
    const itemDetailPage = this.config.theme.pages?.find(
      (page: any) => page?._id === "item_detail"
    );
    return itemDetailPage;
  }

  getCheckoutPage() {
    const checkoutPage = this.config.theme.pages?.find(
      (page: any) => page?._id === "checkout"
    );
    return checkoutPage;
  }

  getFavouritesPage() {
    const favouritePage = this.config.theme.pages?.find(
      (page: any) => page?._id === "favourite"
    );
    return favouritePage;
  }

  getReferEarnPage() {
    const referEarnPage = this.config.theme.pages?.find(
      (page: any) => page?._id === "refer_earn"
    );
    return referEarnPage;
  }

  getProfilePage() {
    const profilePage = this.config.theme.pages?.find(
      (page: any) => page?._id === "profile"
    );
    return profilePage;
  }

  getCartPage() {
    const cartPage = this.config.theme.pages?.find(
      (page: any) => page?._id === "cart"
    );
    return cartPage;
  }

  getStoreLocatorPage() {
    const storeLocatorPage = this.config.theme.pages?.find(
      (page: any) => page?._id === "store_locator"
    );
    return storeLocatorPage;
  }

  getOrderSuccessPage() {
    const orderSuccessPage = this.config.theme.pages?.find(
      (page: any) => page?._id === "order_success"
    );
    return orderSuccessPage;
  }

  getCouponsPage() {
    const couponsPage = this.config.theme.pages?.find(
      (page: any) => page?._id === "coupons"
    );
    return couponsPage;
  }

  getWalletPage() {
    const walletPage = this.config.theme.pages?.find(
      (page: any) => page?._id === "wallet"
    );
    return walletPage;
  }

  getPageNotFoundPage() {
    const pageNotFoundPage = this.config.theme.pages?.find(
      (page: any) => page?._id === "page_not_found"
    );
    return pageNotFoundPage;
  }

  getLandingPage() {
    const landingPage = this.config.theme.pages?.find(
      (page: any) => page?._id === "landing_screen"
    );
    return landingPage;
  }

}

export default new PageSelector();
