import React from "react";
import Head from "next/head";
import configSelector from "../../external/configSelector";

interface SeoDataProps {
    title: string;
    description: string;
    keywords: string;
}

interface OpenGraphDataProps {
  title: string;
  description: string;
  image: string;
  url: string;
}

function SeoData({ seoData, openGraphData }: { seoData: SeoDataProps, openGraphData?: OpenGraphDataProps }) {
  const logo = configSelector.getBizLogoURL();
  const siteName = configSelector.getBizName();

  return (
    <Head>
      <title>{openGraphData?.title || seoData?.title}</title>
      <meta name="description" content={openGraphData?.description || seoData?.description} />
      <meta name="keywords" content={seoData?.keywords} />
    
    
      {/* discord */}
      <meta name="og:site_name" content={siteName}></meta>
      
      
      {/* Facebook */}
      <meta property="og:title" content={openGraphData?.title || seoData?.title} />
      <meta property="og:description" content={openGraphData?.description || seoData?.description} />
      <meta property="og:image" content={openGraphData?.image || logo} />
      <meta property="og:url" content={openGraphData?.url} />

      {/* Twitter */}
      <meta name="twitter:card" content={openGraphData?.image || logo} />
      <meta name="twitter:site" content={openGraphData?.url} />
      <meta name="twitter:title" content={openGraphData?.title || seoData?.title} />
      <meta name="twitter:description" content={openGraphData?.description || seoData?.description} />
      <meta name="twitter:image" content={openGraphData?.image || logo} />

    </Head>
  );
}

export default SeoData;
