import pageSelector from "../pageSelector";
import configSelector from "../configSelector";

export class ScreenHelper {
  isFFSelectorVisible(pathname: string) {
    return (
      this.isMenuPage(pathname) ||
      this.isSearchPage(pathname) ||
      this.isItemDetailPage(pathname) ||
      this.isFavouritesPage(pathname) ||
      this.isByReferralPage(pathname)
    );
  }

  isByReferralPage(pathname: string) {
    return pathname.match(/by_ref/);
  }

  isSearchVisible(pathname: string) {
    return (
      this.isMenuPage(pathname) ||
      this.isItemDetailPage(pathname) ||
      this.isSearchPage(pathname)
    );
  }

  isSearchPage(pathname: string) {
    return pathname === "/search";
  }

  isCheckoutPage(pathname: string) {
    return pathname === "/checkout";
  }

  isAnnouncementVisible(pathname: string) {
    return this.isLandingPage(pathname) || this.isMenuPage(pathname);
  }

  isLandingPage(pathname: string) {
    const isLandingPageEnabled = pageSelector.getLandingPage()?.enabled;
    return (
      isLandingPageEnabled &&
      (pathname === "/" || pathname.match(/landing-page/) !== null)
    );
  }

  isMenuPage(pathname: string) {
    const isLandingPageEnabled = pageSelector.getLandingPage()?.enabled;
    if (!isLandingPageEnabled && pathname === "/") return true;
    return pathname.match(/(menu|category)/);
  }

  isOffersPage(pathname: string) {
    return pathname === "/offers";
  }

  isFavouritesPage(pathname: string) {
    return pathname === "/favourites";
  }

  isPaymentOptionsPage(pathname: string) {
    return pathname === "/choose-payment-method";
  }

  isFloatingStoreStatusVisible(pathname: string) {
    return (
      this.isMenuPage(pathname) ||
      this.isSearchPage(pathname) ||
      this.isItemDetailPage(pathname) ||
      this.isFavouritesPage(pathname) ||
      this.isCheckoutPage(pathname)
    );
  }

  isItemDetailPage(pathname: string) {
    return pathname === "/item/[id]/[slug]";
  }

  getTitle(pathname: string, asPath: string) {
    const customPageConfig = this.getCustomPageConfig();

    if (pathname === "/[slug]") {
      const customPageTitle = customPageConfig.find((page: any) => {
        if (page.path === asPath) {
          return page;
        }
      });

      if (customPageTitle) {
        return customPageTitle.name;
      }
    }

    switch (pathname) {
      case "/offers":
        return "Offers";
      case "/menu":
        return "Menu";
      case "/checkout":
        return "Checkout";
      case "/search":
        return "Search";
      case "/add-money":
        return "Add Money";
      case "/address":
        return "Manage Address";
      case "/wallet":
        return "Wallet Balance";
      case "/orders/[id]":
        return "Track Order";
      case "/refer":
        return "Refer & Earn";
      case "/orders":
        return "Order History";
      case "/profile":
        return "Profile";
      case "/choose-payment-method":
        return "Choose Payment Method";
      case "/store-locator":
        return "Store Locator";

      default:
        return "";
    }
  }

  getCustomPageConfig() {
    return configSelector.getCustomPages().map((page: any) => {
      return {
        name: page.name,
        path: page.path,
      };
    });
  }

  getSeoData(pathname: string) {
    switch (pathname) {
      case "/menu":
        return pageSelector.getMenuPage()?.seo || {};

      case "/orders":
        return pageSelector.getOrderHistoryPage()?.seo || {};

      case "/item[id]":
        return pageSelector.getItemDetailPage()?.seo || {};

      case "/checkout":
        return pageSelector.getCheckoutPage()?.seo || {};

      case "/favorites":
        return pageSelector.getFavouritesPage()?.seo || {};

      case "/refer":
        return pageSelector.getReferEarnPage()?.seo || {};

      case "/profile":
        return pageSelector.getProfilePage()?.seo || {};

      case "stores":
        return pageSelector.getStoreLocatorPage()?.seo || {};

      case "orders[id]":
        return pageSelector.getOrderSuccessPage()?.seo || {};

      case "/offers":
        return pageSelector.getCouponsPage()?.seo || {};

      case "/wallet":
        return pageSelector.getWalletPage()?.seo || {};

      case "/":
        return pageSelector.getLandingPage()?.seo || {};

      default:
        return {};
    }
  }
}

export default new ScreenHelper();
