import { SentryEvent } from "@sentry/types";
import configSelector from "../external/configSelector";

export function filterLocalhostErrors(event: SentryEvent) {
  // Get the URL where the error occurred
  const env = configSelector.getEnv();
  const url = event.request && event.request.url;

  // Check if the URL contains "localhost" or any other excluded environment
  if (
    (url && url.includes("localhost")) ||
    ["staging", "pos-int", "local"].includes(env)
  ) {
    return null; // Return null to discard the event
  }

  return event;
}
