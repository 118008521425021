import { useEffect, useState } from "react";
import MerakiSDK from "@urbanpiper-engineering/meraki-sdk";
import CheckoutJSON from "../editor-static-data/checkout.json"

export default function useIframe() {
    const [loadedInIframe, setLoadedInIframe] = useState(false);

    const isInIframe = () => {
        try {
            return window.self !== window.top;
        } catch (e) {
            return true;
        }
    }

    useEffect(() => {
        //load dummy cart for editor preview
        if (loadedInIframe) {
            MerakiSDK.replaceCart(CheckoutJSON.cart)
        }
    }, [loadedInIframe])

    useEffect(() => {
        if (isInIframe()) {
            setLoadedInIframe(true);
        } else {
            setLoadedInIframe(false);
        }
    }, [])

    return { loadedInIframe };
}