const { initReactI18next } = require("react-i18next");

module.exports = {
  debug: false,
  i18n: {
    defaultLocale: "en",
    locales: ['en', 'ar', 'es'],
  },
  localeDetection: false,
  ns: ["common"],
  localeStructure: "{{lng}}",
  serializeConfig: false,
  use: typeof window !== "undefined" ? [initReactI18next] : [],
};
