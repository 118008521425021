import { readFile } from "fs/promises";
import configSelector from "./configSelector";
import configJSON from '../public/config/root.config.json' assert { type: 'json'};

export class BaseSelector {

    protected config: any;

    constructor() {
        this.config = configJSON;
    }

    async updateConfigFromCache(bizId: number) {
        try {
            const config = JSON.parse(
                await (await readFile(configSelector.getCachePath(bizId))).toString()
            );
            this.config = config;
        } catch (err) {
            console.log(`config issue`, err);
        }
    }

    async updateConfigFromJSON() {
        try {
            const config = await (await fetch(`http://0.0.0.0:${process.env.PORT || 3000}/config/root.config.json`)).json();
            this.config = config;
        } catch (err) {
            console.log(`web not working, checking file`);
            await this.updateConfigFromDisk();
        }
    }

    async updateConfigFromDisk() {
        try {
            const config = JSON.parse((await readFile(`public/config/root.config.json`)).toString('utf-8'));
            this.config = config;
        } catch (err) {
            console.log(`config issue`);
        }
    }

    updateConfig(config: any) {
        this.config = config;
    }


    getConfigJSON() {
        return this.config;
    }

    getCachePath(bizId: number) {
        return `/tmp/${bizId}_cache.config.json`;
    }

}