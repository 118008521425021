import { Channel } from "@urbanpiper-engineering/meraki-sdk/dist/esm/common/enums/channel.enum.js";
import { BaseSelector } from "./baseSelector";

class ConfigSelector extends BaseSelector {

  getSDKConfig() {
    return {
      bizApiKey: this.getAuthToken(),
      bizId: this.getBizId(),
      channel: "web" as Channel,
      url: this.getGraphqlBase(),
      theme: "luna",
      captchaKey: "6LefjhcnAAAAADGL9_ZcwQAFcBtwsZY2E1gD_7He",
    };
  }

  // Pallete
  getBiz() {
    return this.config.biz;
  }

  getAPIBase() {
    switch (this.config.biz.env) {
      case "local":
        return "http://localhost:4001/p";
      case "production":
        return "https://meraki-api.svc.urbanpiper.com/p";
      case "staging":
        return "https://meraki-api-staging.services-staging.urbanpiper.com/p";
      case "pos-int":
        return "https://meraki-api-posint.services-staging.urbanpiper.com/p";
      default:
        return "https://meraki-api.svc.urbanpiper.com/p";
    }
  }

  getHotjar() {
    return this.getAccessKeys().analytics.hotjar;
  }

  getGraphqlBase() {
    switch (this.config?.biz?.env) {
      case "local":
        return "http://localhost:4001/graphql";
      case "production":
        return "https://meraki-api.svc.urbanpiper.com/graphql";
      case "staging":
        return "https://meraki-api-staging.services-staging.urbanpiper.com/graphql";
      case "pos-int":
        return "https://meraki-api-posint.services-staging.urbanpiper.com/graphql";
      default:
        return "https://meraki-api.svc.urbanpiper.com/graphql";
    }
  }

  getGraphqlWS() {
    switch (this.config?.biz?.env) {
      case "local":
        return "ws://localhost:4001/graphql";
      case "production":
        return "wss://meraki-api.svc.urbanpiper.com/graphql";
      case "staging":
        return "wss://meraki-api-staging.services-staging.urbanpiper.com/graphql";
      case "pos-int":
        return "wss://meraki-api-posint.services-staging.urbanpiper.com/graphql";
      default:
        return "wss://meraki-api.svc.urbanpiper.com/graphql";
    }
  }

  getMerakiEditorBase() {
    switch (this.config?.biz?.env) {
      case "production":
        return "https://atlas.urbanpiper.com";
      case "staging":
        return "https://test-atlas.urbanpiper.com";
      case "pos-int":
        return "https://test-atlas.urbanpiper.com";
      default:
        return "https://atlas.urbanpiper.com";
    }
  }

  getEnv() {
    return this.config?.biz?.env;
  }

  getAuthToken() {
    const biz = this.getBiz();
    return biz?.username;
  }

  getThemeColors() {
    return this.config.theme.customizations.colors;
  }

  getFlags() {
    return this.config.customizations;
  }

  getAccessKeys() {
    return this.config.customizations.accessKeys;
  }

  getCustomPages() {
    return this.config.customPages;
  }

  getGlobalComponent({ name }: any) {
    return this.config.theme.components?.find(
      (component: any) => component?._id === name
    );
  }

  getPageSEO({ name, locale }: any) {
    return this.config.theme.pages?.find((page: any) => page?._id === name)
      ?.seo?.[locale] || {};
  }

  getDeliveryPickupConfig() {
    return this.getFlags().deliveryPickup;
  }

  getWallet() {
    return this.getFlags().wallet;
  }

  getPrimaryColor() {
    let commonColors = this.getThemeColors();
    return commonColors.primaryColor;
  }

  getSecondaryColor() {
    let commonColors = this.getThemeColors();
    return commonColors.secondaryColor;
  }

  getTertiaryColor() {
    let commonColors = this.getThemeColors();
    return commonColors.tertiaryColor;
  }

  getSuccessColor() {
    let commonColors = this.getThemeColors();
    return commonColors.successColor;
  }

  getFailureColor() {
    let commonColors = this.getThemeColors();
    return commonColors.failureColor;
  }

  // Text
  getPrimaryTextColor() {
    let commonColors = this.getThemeColors();
    return commonColors.primaryTextColor;
  }

  getSecondaryTextColor() {
    let commonColors = this.getThemeColors();
    return commonColors.secondaryTextColor;
  }

  //font
  getFontFamily() {
    const fontFamily = this.config.theme?.customizations.font;

    if (!fontFamily || typeof fontFamily !== "string") {
      return "Open Sans";
    }

    return fontFamily;
  }

  //get enabled fulfilmentTypes
  getFulfilmentTypes() {
    const types: String[] = [];
    if (this.getFlags().deliveryPickup.enableDelivery) {
      types.push("delivery");
    }

    if (this.getFlags().deliveryPickup.enablePickup) {
      types.push("pickup");
    }

    return types;
  }

  getUpfrontFulfillmentTimePromptFlag() {
    return this.getFlags().deliveryPickup.upfrontFulfillmentTimePrompt;
  }

  //get default fulfilmentType
  getDefaultFulfilmentMode() {
    let defaultMode: String = this.getFlags().deliveryPickup.defaultMode;
    if (!defaultMode) {
      const types = this.getFulfilmentTypes();
      defaultMode = types.length ? types[0] : "delivery";
    }
    return defaultMode;
  }

  getReferral() {
    return this.getAccessKeys().referral;
  }

  getHideDeliverNow() {
    return this.getDeliveryPickupConfig().hideDeliverPickupNow;
  }

  getEnableTimeSlots() {
    return this.getDeliveryPickupConfig().enableTimeSlots;
  }

  getMaxPreOrderDate() {
    return this.getDeliveryPickupConfig().maxPreOrderDate;
  }

  getHideDeliverNowAfterOffset() {
    return this.getDeliveryPickupConfig().hideDeliverPickupNowAfterOffset;
  }

  getBizName() {
    return this.getBiz().name;
  }

  getBizLogoURL() {
    return this.getBiz().logoUrl;
  }
  getBizSquareLogoURLMobile() {
    return this.getBiz().logoUrlSquare;
  }

  getMinReloadButtonValue() {
    return this.getWallet().minReloadBtnVal;
  }
  getDisableReload() {
    return this.getWallet().disableReload;
  }

  getSortByPriceLabel() {
    return "Price (Low to High)";
  }

  // custom pages
  getTermsPageContent() {
    const page: any = this.getCustomPages()?.find(
      (page: any) => page?._id === "terms"
    );
    return page?.content;
  }
  getTermsPageUrl() {
    const page: any = this.getCustomPages()?.find((page: any) =>
      page?.name.match(/term/i)
    );
    return page?.path;
  }

  getPrivacyPageContent() {
    const page: any = this.getCustomPages()?.find(
      (page: any) => page?._id === "privacy"
    );
    return page?.content;
  }

  // header background
  getHeaderColors() {
    const headerComponent = this.getGlobalComponent({ name: "header" });

    return {
      backgroundColor: headerComponent?.customizations?.backgroundColor,
    };
  }

  getFreshChat() {
    return this.getAccessKeys().chat.freshchat;
  }

  getMixPanel() {
    return this.getAccessKeys().analytics.mixpanel;
  }

  getGTM() {
    return this.getAccessKeys().analytics.gtm;
  }
  getGA() {
    return this.getAccessKeys().analytics.ga;
  }

  getGoogleMapsKey() {
    return this.getAccessKeys().misc.googleMapKey;
  }

  // social login
  getFacebookLogin() {
    return this.getAccessKeys().socialLogin.facebook;
  }

  getGoogleLogin() {
    return this.getAccessKeys().socialLogin.google;
  }

  // wallet
  getWalletConfig() {
    return this.getFlags().wallet;
  }

  getFavicon() {
    return this.getBiz().favicon;
  }

  getSocialMediaLinks() {
    const component = this.getGlobalComponent({ name: "footer" });
    return component?.customizations?.socialMediaLinks || {};
  }

  getSocialMediaLinksSSR() {
    const component: any = this.getGlobalComponent({ name: "footer" });
    const keys: string[] = Object.keys(
      component?.customizations?.socialMediaLinks || {}
    );
    return keys.reduce((result: any, key: string) => {
      if (component?.customizations?.socialMediaLinks[key]?.length > 0) {
        result.push({
          media: key,
          link: component?.customizations?.socialMediaLinks[key],
        });
      }
      return result;
    }, []);
  }

  getExternalPageLinks() {
    const component = this.getGlobalComponent({ name: "footer" });
    return (component?.customizations?.externalPageLinks || []).map(
      (page: any) => ({
        name: page.name,
        href: page.link || page.url || page.path,
      })
    );
  }

  getTopHeaderLeftLink() {
    const component = this.getGlobalComponent({ name: "topbar" });

    return component?.customizations?.leftLink || {};
  }

  getTopHeaderRightLink() {
    const component = this.getGlobalComponent({ name: "topbar" });

    return component?.customizations?.rightLink || {};
  }

  getHideOrderingTime() {
    return this.getDeliveryPickupConfig().hideOrderingTime;
  }

  getCopyrightText() {
    const component = this.getGlobalComponent({ name: "footer" });

    return component?.customizations?.copyrightText;
  }

  getContactUs() {
    const component = this.getGlobalComponent({ name: "footer" });

    return component?.customizations?.contactUs;
  }

  getGuestCheckout() {
    return this.getFlags().guestCheckout || {};
  }

  getIsGuestCheckoutEnabled() {
    const checkout = this.getGuestCheckout();
    if (typeof checkout.enable === "boolean") {
      return checkout.enable;
    }

    // enabled is not defined. we default to true
    return true;
  }

  getIsCODEnabledGuestCheckout() {
    return this.getGuestCheckout().cashOnDelivery;
  }

  getIsEmailRequiredGuestCheckout() {
    const guestCheckout = this.config.customizations.guestCheckout;
    if (typeof guestCheckout.emailRequired === "boolean") {
      return guestCheckout.emailRequired;
    }

    // emailRequired is not defined. we default to false
    return false;
  }

  getDefaultLanguage() {
    return this.config.biz?.defaultLanguage;
  }

  getNpsFeedback() {
    return this.getFlags().npsFeedback || {};
  }

  getImagesSizeFromConfig() {
    return this.config.customizations.imageSize;
  }

  getOrderSuccessDeliveryMessage() {
    return this.getFlags().deliveryPickup?.order_detail_delivery_dt_msg || "";
  }

  getUrbanPiperBrandingFlag() {
    if (this.getFlags()?.showUrbanpiperBranding === false) {
      return false;
    }
    return true;
  }

  getReferralBool() {
    return this.getFlags()?.accessKeys?.referral?.enable;
  }

  getMode() {
    return this.getBiz()?.mode;
  }

  getSubPath() {
    return ``;
  }

  getBizId() {
    return Number(this.getBiz()?.id);
  }

  getThemeColorSelection() {
    return {
      primaryColor: this.getPrimaryColor(),
      secondaryColor: this.getSecondaryColor(),
      tertiaryColor: this.getTertiaryColor(),
      successColor: this.getSuccessColor(),
      failureColor: this.getFailureColor(),
      primaryTextColor: this.getPrimaryTextColor(),
      secondaryTextColor: this.getSecondaryTextColor(),
      headerBackgroundColor: "#ffffff",
    };
  }

}

export default new ConfigSelector();
