import React, { Suspense, useEffect } from "react";
import dynamic from "next/dynamic";
import Router from "next/router";
import Styles from "./index.module.scss";
import SeoData from "../seo_data";
import screenHelper from "../../external/helpers/screenHelper";
import { useRouter } from "next/router";
import configSelector from "../../external/configSelector";
import "nprogress/nprogress.css";
import NProgress from "nprogress";
import MerakiSDK, {
  ANALYTICS_EVENTS,
} from "@urbanpiper-engineering/meraki-sdk";
import { StoreState } from "@urbanpiper-engineering/meraki-sdk/dist/esm/stores/states/store.state";
import useIframe from "../../hooks/iframe.hook";
import dayjs from "dayjs";
import { UserState } from "@urbanpiper-engineering/meraki-sdk/dist/esm/user/states/user.state";
import AnouncementBar from "@urbanpiper-engineering/meraki-components/dist/Cells/AnouncementBar";
import { useIsMobile } from "../../hooks/is_mobile.hook";

const FloatingStack = dynamic(
  () =>
    import(
      "@urbanpiper-engineering/meraki-components/dist/Atoms/FloatingStack"
    ),
  { ssr: false }
);

const CustomizeThemeBar = dynamic(
  () =>
    import(
      "@urbanpiper-engineering/meraki-components/dist/Molecules/CustomizeThemeBar"
    ),
  { ssr: false }
);

const FloatingStatus = dynamic(
  () =>
    import(
      "@urbanpiper-engineering/meraki-components/dist/Molecules/FloatingStatus"
    ),
  { ssr: false }
);

const Header = dynamic(() => import("../header"), { suspense: true });
const Footer = dynamic(() => import("../footer"), { suspense: true });
const TopBar = dynamic(() => import("../top_bar"), { suspense: true });

NProgress.configure({ showSpinner: false });
Router.events.on("routeChangeStart", () => NProgress.start());
Router.events.on("routeChangeComplete", () => NProgress.done());
Router.events.on("routeChangeError", () => NProgress.done());

const ItemOptionsDialog = dynamic(() => import("../item_options"), {
  ssr: false,
});

function Layout({
  children,
  footer,
  className,
  webBgImg,
  supportedLanguages,
  businessInfo,
  locale,
}: {
  children: React.ReactNode;
  footer: any;
  className: string;
  webBgImg: string;
  supportedLanguages: string[];
  businessInfo: any;
  locale: string;
}) {
  const isMobileView = useIsMobile();
  const router = useRouter();
  const seoData = screenHelper.getSeoData(router.pathname);
  const useUserState = MerakiSDK.getUserState().hook();
  const activeLanguage = useUserState(
    (state: typeof  UserState) => state.language
  ) || configSelector.getDefaultLanguage() || "en";
  const [announcement, setAnnouncement] = React.useState<any>(null);

  const useStoreState = MerakiSDK.getStoreState().hook();
  const isStoreClosed = useStoreState(
    (state: typeof StoreState) => state.isStoreClosed
  );
  const selectedStoreId = useStoreState(
    (state: typeof StoreState) => state.selectedStore?.id
  );
  const [previewMode, setPreviewMode] = React.useState(false);
  const { loadedInIframe } = useIframe();


  const isFloatingStoreStatusVisible =
    screenHelper.isFloatingStoreStatusVisible(router.pathname) && isStoreClosed;

  const isObjectEmpty = (obj: any) => {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
  };

  useEffect(() => {
    window.history.scrollRestoration = "manual";
  }, []);

  useEffect(()=>{
     MerakiSDK.getAnnouncement()
      .then((res:any) => {
        setAnnouncement(res);
      })
      .catch(()=>{
        //do nothing
      })

  },[ selectedStoreId])


  const onCustomizeClick = () => {
    const merakiEditorBase = configSelector.getMerakiEditorBase();
    window.open(`${merakiEditorBase}/meraki-web/editor?theme=luna`, "_blank");
  };

  useEffect(() => {
    const urlBizId = window.location.hostname?.split(".")?.[0] || "";
    const bizId = configSelector.getBizId();
    let isScreenShotMode = false;

    try {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const screenshotMode = urlParams.get("screenshot_mode");
      isScreenShotMode = screenshotMode === "1";
    } catch (_) {
      isScreenShotMode = false;
    }

    try {
      if (Number(urlBizId) === Number(bizId) && !isScreenShotMode) {
        setPreviewMode(true);
      } else {
        setPreviewMode(false);
      }
    } catch {
      setPreviewMode(false);
    }
  }, [businessInfo, router?.query]);

  useEffect(() => {
    const startOfHour = dayjs().startOf("hour").format("hh:mm");
    const endOfHour = dayjs().endOf("hour").format("hh:mm");
    const intervalOfDay = `${startOfHour} - ${endOfHour}`;

    try {
      const eventObj = {
        interval_of_day: intervalOfDay,
      };
      MerakiSDK.analyticsPublish(ANALYTICS_EVENTS.APP_LAUNCHED, eventObj);
    }
    catch(error){
      console.log("AnalyticsPublish: ", ANALYTICS_EVENTS.APP_LAUNCHED, error)
    }

  }, []);

  useEffect(() => {
    (async () => {
      try {
        await MerakiSDK.refreshAuthToken();
      } catch (error) {
        //To be decided
      }
    })();
  }, []);


  return (
    <div
      className={`${Styles.layout} ${className} ${
        loadedInIframe ? Styles.layoutDisabled : ""
      } `}
    >
      {isObjectEmpty(seoData) ? null : <SeoData seoData={seoData[activeLanguage]} />}

      {isFloatingStoreStatusVisible ? (
        <FloatingStack order={2}>
          <FloatingStatus variant="store" />
        </FloatingStack>
      ) : null}

      <ItemOptionsDialog />

     {screenHelper.isAnnouncementVisible(router.pathname) && announcement?.description  ?
      <AnouncementBar
      announcement={announcement.description || ""}
      url={announcement?.url || ""}
      backgroundColor={announcement?.styles?.color || "#000"}
    />
     : null}

      <TopBar />

      <Header
        isMobileView={isMobileView}
        webBgImg={webBgImg}
        supportedLanguages={supportedLanguages}
        businessInfo={businessInfo}
        locale={locale}
      />

      <div className={Styles.layout__children}>{children}</div>

      <Suspense fallback={`...`}>
        <Footer {...footer} />
      </Suspense>

      {previewMode && !loadedInIframe ? (
        <FloatingStack order={-4}>
          <CustomizeThemeBar
            onCustomizeClick={onCustomizeClick}
            classes={Styles.customizeThemeBar}
          />
        </FloatingStack>
      ) : null}
    </div>
  );
}

export default Layout;


